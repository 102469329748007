module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P5WV7F5SY1"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"177890397264683"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
